import React from 'react';
import moment from 'moment'

export const NO_FORMAT =  "DD.MM.YYYY HH:mm:ss";
const ISO_FORMAT =  "YYYY-MM-DD HH:mm:ss";
const ISO_8601_FORMAT =  "YYYY-MM-DDTHH:mm:ss.SSS Z";

const dateFormats = [NO_FORMAT, ISO_FORMAT, ISO_8601_FORMAT];
const EMPTY_STRING = "";

const defaultFormat = NO_FORMAT;

//Parse date-time as UTC and display as local time
const FormatDateTime = ({dateTime, format = defaultFormat}) => {
    if(!dateTime) {
        return EMPTY_STRING;
    }
    const d = moment.utc(dateTime, dateFormats).local();
    return d.isValid() ? (<span>{d.format(format)}</span>) : EMPTY_STRING;
};

//Parse date-time as UTC
export const ParseDateTime = (dateTimeStr) => {
    const d = moment.utc(dateTimeStr, dateFormats);
    return d.isValid() ? d.toDate() : undefined;
};
     
export default FormatDateTime
