import { combineReducers } from 'redux-immutable'

import refs from './refs.js'
import refTypes from "./refTypes";
import refSubtypes from "./refSubtypes";

export default combineReducers({
    refs,
    refTypes,
    refSubtypes
  })