import * as types from '../index'
import {makeActionCreator} from '../../util/actionUtils'
import {fetchJson, patchJson} from '../../util/fetchUtils'
import {addDays} from 'date-fns';
import moment from 'moment'
import {t} from "react-i18nify";
import {ISO_FORMAT} from "../../components/util/FormatDate";

export const MAX_DAYS_RANGE = 120;

const fetchRefsRequest = makeActionCreator(types.FETCH_REFS.REQUEST);
const fetchRefsSuccess = makeActionCreator(types.FETCH_REFS.SUCCESS, 'refs');
const fetchRefsFailure = makeActionCreator(types.FETCH_REFS.FAILURE, 'error');
export const fetchRefs = (startDate, endDate) => dispatch => {
    if(startDate == null || endDate == null) {
        startDate = addDays(new Date(), - (MAX_DAYS_RANGE - 1));
        endDate = new Date();
    }

    dispatch(fetchRefsRequest());
    let startDateStr = moment(startDate).format(ISO_FORMAT);
    let endDateStr = moment(endDate).format(ISO_FORMAT);
    return fetchJson("/tiltaksreferanse/refs?startDate=" + startDateStr + "&endDate=" + endDateStr)
        .then(
            refs => dispatch(fetchRefsSuccess(refs)),
            error => dispatch(fetchRefsFailure(error))
        )
};

const fetchRefRequest = makeActionCreator(types.FETCH_REF.REQUEST);
const fetchRefSuccess = makeActionCreator(types.FETCH_REF.SUCCESS, 'ref');
const fetchRefFailure = makeActionCreator(types.FETCH_REF.FAILURE, 'error');
export const fetchRef = (refId) => dispatch => {
    dispatch(fetchRefRequest());
    return fetchJson("/tiltaksreferanse/refs/" + refId)
        .then(
            ref => dispatch(fetchRefSuccess(ref)),
            error => {
                if(error.status === 404) {
                    alert(t('refs.table.alert.refNotFound', {refId}));
                }

                dispatch(fetchRefFailure(error))
            }
        );
};

export const updatedRef = makeActionCreator(types.UPDATED_REF, 'ref');
const updatedRefs = makeActionCreator(types.UPDATED_REFS, 'refs');

export const bundleRefs = (ids, group) => dispatch => {
    const idString = ids.join(",");
    const url = '/tiltaksreferanse/refs/bundle?ids=' + idString + '&groupId=' + group;

    return patchJson(url).then(json => {
        dispatch(updatedRefs(json))
    })
};