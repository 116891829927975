import Immutable from 'immutable';

const fetchStatus = (state, status, error = null) =>
        state.set('status', status)
             .set('lastError', error)
             .set('hadSuccessAtleastOnce', state.get('hadSuccessAtleastOnce') || status === "success")

const initialState = Immutable.fromJS({status:'init', hadSuccessAtleastOnce: false})

export const createFetchActions = (prefix) => ({
    REQUEST:prefix+'.REQUEST',
    SUCCESS:prefix+'.SUCCESS',
    FAILURE:prefix+'.FAILURE',
    RESET_SUCCESS:prefix+'.RESET_SUCCESS',
})

export const createFetchStatusReducer = ({REQUEST, SUCCESS, FAILURE, RESET_SUCCESS}) => (state = initialState, action = {}) => {
    switch (action.type) {
        case RESET_SUCCESS: 
            return state.set('hadSuccessAtleastOnce', false)
    
        case REQUEST:
            return fetchStatus(state, 'fetching')

        case SUCCESS:
            return fetchStatus(state, 'success')

        case FAILURE:
            return fetchStatus(state, 'failure', action.error)

        default:
            return state;
    }
}
