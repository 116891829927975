import { createSelector } from 'reselect'
import Immutable from 'immutable'

const fetchStatusSelector = (state) => state.get('gui') && state.get('gui').get('fetchStatus')

export const loadingStatusSelector = createSelector(
    [fetchStatusSelector],
    items => {
        const totalItems = (items.size || 1) + 1 //Starting the progress bar at 2, to continue where the gsp leaves.
        const remaining = items.filter(v => {
            return v.get('hadSuccessAtleastOnce') === false
        }).size
       
        const loading = remaining !== 0
        const percent = Math.round( (totalItems - remaining) / totalItems * 100 )
        const result = Immutable.Map({loading:loading , totalItems: totalItems, remaining: remaining, percent: percent })
        return result
    }
    
)

export const connectionStatus = createSelector(
    [fetchStatusSelector],
    items => {
        const result = items.some(v => {
            return v.get('status') === 'failure'
        })
        return result
    }
    
)