import Immutable from 'immutable';
import * as types from '../../actions'

const refs = (state = Immutable.fromJS([]), action = {}) => {

  let ref = null;

  switch (action.type) {
    case types.FETCH_REFS.SUCCESS:
      return Immutable.fromJS(action.refs);
    case types.FETCH_REF.SUCCESS:
      ref = Immutable.fromJS(action.ref);
      const fetchedIndex = state.findIndex(d => d.get('id') === ref.get('id'));
      if(fetchedIndex < 0) {
        ref = ref.update('hidden', () => true);
        return state.push(ref);
      }
      return state.update(fetchedIndex, () => ref);
    case types.UPDATED_REF:
      ref = Immutable.fromJS(action.ref);
      const updateIndex = state.findIndex(d => d.get('id') === ref.get('id'));
      if(updateIndex < 0) {
        return state.push(ref);
      }
      return state.update(updateIndex, () => ref);
    case types.UPDATED_REFS:
      let updatedState = state;
      let refs = Immutable.fromJS(action.refs);

      refs.forEach(ref => {
        const updateIndex = updatedState.findIndex(d => d.get('id') === ref.get('id'));
        if(updateIndex < 0) {
          updatedState = updatedState.push(ref);
        }
        updatedState = updatedState.update(updateIndex, () => ref);
      });

      return updatedState;
    default:
      return state
  }
};

export default refs