import {createSelector} from "reselect";

export const getAllRefs = (state) => state.get('data').get('refs');
const getIdFromProps = (state, props) => parseInt(props.id || (props.match && props.match.params.id) || (typeof props.get !== "undefined" && props.get('id')) || null);

export const getRef = createSelector(
    [getAllRefs, getIdFromProps],
    (all, id) => {
      return all.find(t => t.get('id') === id);
    }
);

