import React from 'react';
import moment from 'moment'

export const NO_FORMAT =  "DD.MM.YYYY";
export const ISO_FORMAT =  "YYYY-MM-DD";

const dateFormats = [NO_FORMAT, ISO_FORMAT];
const EMPTY_STRING = "";

const defaultFormat =  NO_FORMAT;
const FormatDate = ({date, format = defaultFormat}) => {
    if(!date) {
        return EMPTY_STRING;
    }

    const d = moment(date, dateFormats);
    return d.isValid() ? (<span>{d.format(format)}</span>) : EMPTY_STRING;
};

export const FormatDateToString = (date, format = defaultFormat) => {
    if(!date) {
        return undefined;
    }

    const d = moment(date, dateFormats);
    return d.isValid() ? d.format(format) : undefined;
};

export const Parse = (dateStr) => {
    const d = moment(dateStr, dateFormats);
    return d.isValid() ? d.toDate() : undefined;
};
     
export default FormatDate
