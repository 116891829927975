import React from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import RefsTable from "./RefsTable";
import {getAllRefs} from "../../selectors/refs/refs";

var RefsPage = ({tableSelector, ...rest}) =>
    <div className="container-fluid">
      <RefsTable selector={tableSelector} {...rest}/>
    </div>;

RefsPage = withRouter(connect(
    () => {
      return {
        tableSelector: getAllRefs
      }
    }
)(RefsPage));

export default RefsPage;