export default {
  "api": {
    "versionParam": "api-version",
    "version": "1",
    "path": "/tiltaksreferanse/",
    "urlPrefix": "https://lb.",
    "clientName": "Tiltaksreferanse",
    "prodHost": "https://lb.trafikksystem.vydev.io"
  },
  "loginUrl": {
    "local": "https://auth.test.cognito.vydev.io/login?client_id=7370hb9crlm4ncmfs8b25r5vbh&response_type=token&scope=openid+tiltaksreferanse/get.ref+tiltaksreferanse/get.ref.meta+tiltaksreferanse/update.ref+tiltaksreferanse/use.ref&redirect_uri=http://localhost:3000",
    "test": "https://auth.test.cognito.vydev.io/login?client_id=7370hb9crlm4ncmfs8b25r5vbh&response_type=token&scope=openid+tiltaksreferanse/get.ref+tiltaksreferanse/get.ref.meta+tiltaksreferanse/update.ref+tiltaksreferanse/use.ref&redirect_uri=https://tiltaksreferanse-gui.test.trafikksystem.vydev.io/",
    "stage": null,
    "prod": "https://auth.cognito.vydev.io/login?client_id=3v6j3kudhm7iftft2gbuj8o41o&response_type=token&scope=openid+tiltaksreferanse/get.ref+tiltaksreferanse/get.ref.meta+tiltaksreferanse/update.ref+tiltaksreferanse/use.ref&redirect_uri=https://referansenummer.vy.no/"
  },
  auth: {
    test: {
      baseUrl: "https://auth.test.cognito.vydev.io",
      clientId: "7370hb9crlm4ncmfs8b25r5vbh",
      redirectUri: "https://tiltaksreferanse-gui.test.trafikksystem.vydev.io/",
      jwksUrl: "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_Z53b9AbeT/.well-known/jwks.json",
      SCOPE: "openid+tiltaksreferanse/get.ref+tiltaksreferanse/get.ref.meta+tiltaksreferanse/update.ref+tiltaksreferanse/use.ref",
    },
    prod: {
      baseUrl: "https://auth.cognito.vydev.io",
      clientId: "3v6j3kudhm7iftft2gbuj8o41o",
      redirectUri: "https://referansenummer.vy.no/",
      jwksUrl: "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_e6o46c1oE/.well-known/jwks.json",
      SCOPE: "openid+tiltaksreferanse/get.ref+tiltaksreferanse/get.ref.meta+tiltaksreferanse/update.ref+tiltaksreferanse/use.ref"
    }
  }
}