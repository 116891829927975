import React from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {SubmissionError} from 'redux-form';
import {updatedRef} from "../../actions/creators/refs";
import RefForm from "./RefForm";
import {getAllRefTypesWithLabel} from "../../selectors/refs/refTypes";
import {getRef} from "../../selectors/refs/refs";
import RefAccessLogTable from "./RefAccessLogTable";
import {t} from "react-i18nify";
import {patchJson, postJson} from "../../util/fetchUtils";
import Immutable from 'immutable';
import {FormatDateToString, ISO_FORMAT} from "../util/FormatDate";
import config from '../../config'

function submit(values) {
  values = values.updateIn(['trainNumbers'], trainNumbers => {
    if(!trainNumbers) {
      return [];
    }

    return trainNumbers.split(",").map(t => t.trim()).filter(t => t.length > 0)
  });

  values = values.updateIn(['date'], date => {
    return FormatDateToString(date, ISO_FORMAT)
  });

  const update = values.get('id') != null;
  const url = update ? '/tiltaksreferanse/refs/' + values.get('id'): '/tiltaksreferanse/refs';
  const apiCall = update ? patchJson(url, JSON.stringify(values)) : postJson(url, JSON.stringify(values));

  return apiCall.then(json => {
    return json
  })
  .catch(response => {
    if(!response) {
      throw new SubmissionError();
    }

    return response.json().then(json => {
      var formMap = {};

      if(json.code === "PLAN_ID_USED") {
        formMap.infrastrukturPlanId = t('refs.detail.form.planIdUsedError');
      }
      else if(json.code === "ISIRK_USED") {
        formMap.isirkId = t('refs.detail.form.isirkUsedError');
      }

      throw new SubmissionError(formMap);
    });
  })
}

var RefEditPage = ({initialValues, logLines, update, onSubmitSuccess, history, ...rest}) =>
    <div className="container-fluid">
      <h2 className="text-center">{(initialValues.get('refId') && t('refs.detail.form.headingUpdate', {refId: initialValues.get('refId')})) || t('refs.detail.form.headingNew')}</h2>
      <div className="h-25p"/>
      <RefForm onSubmitSuccess={onSubmitSuccess(history)} initialValues={initialValues} update={update} {...rest}/>

      {update && ( <div>
        <div className="h-25p"/>
        <h2 className="text-center">{t('refs.detail.accessLogTable.heading')}</h2>
        <div className="h-25p"/>
        <RefAccessLogTable logLines={logLines} tableName="accessLog"/>
          </div>
      )}
    </div>;

RefEditPage = withRouter(connect(
    (state, ownProps) => {
      let ref = getRef(state, ownProps);
      let initialValues;
      if(ref) {
        initialValues = ref.updateIn(['trainNumbers'], trainNumbers => trainNumbers && trainNumbers.join(", "));
      }
      else {
        initialValues = Immutable.fromJS({reuseRef: false, date: new Date(), originSystem: config.api.clientName, type: 'PERSONNEL', subType: 'DRIVER'});
      }

      const hash = window.location.hash;
      const submitMessage = (new URLSearchParams(hash.replace("#", ""))).get("submit-message");

      return {
        initialValues,
        logLines: initialValues.get('accessLog'),
        refTypes: getAllRefTypesWithLabel(state),
        update: (initialValues.get('id') != null),
        submitMessage,
        ...ownProps
      }
    },
    () => {
      return {
        onSubmit: (formData) => {
          return submit(formData);
        },
        onSubmitFail: (error) => {
          console.log("Submit failed!");
        },
        onSubmitSuccess: (history) => (data, dispatch) => {
          dispatch(updatedRef(data));
          history.push("/"); //Hack to get the form to display the just submitted data. Do not know why it works...

          var filteredTrainNumbers = data.trainNumbers && data.trainNumbers.filter(t => t.length > 0);

          if((!filteredTrainNumbers || filteredTrainNumbers.length === 0) && !data.station && !data.isirkId && !data.infrastrukturPlanId) {
            history.push("/refs/edit/" + data.id + "#submit-message=noIds");
          }
          else {
            history.push("/refs/edit/" + data.id);
          }


        }
      }
    }

)(RefEditPage));

export default RefEditPage;