import * as types from '../index'
import {makeActionCreator} from '../../util/actionUtils'
import {fetchJson} from '../../util/fetchUtils'

const fetchRefTypesRequest = makeActionCreator(types.FETCH_REF_TYPES.REQUEST);
const fetchRefTypesSuccess = makeActionCreator(types.FETCH_REF_TYPES.SUCCESS, 'refTypes');
const fetchRefTypesFailure = makeActionCreator(types.FETCH_REF_TYPES.FAILURE, 'error');

export const fetchRefTypes = () => dispatch => {
    dispatch(fetchRefTypesRequest());
    return fetchJson(`/tiltaksreferanse/refs/types`)
        .then(
            refTypes => dispatch(fetchRefTypesSuccess(refTypes)),
            error => dispatch(fetchRefTypesFailure(error))
        )
};
