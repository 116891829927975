import axios from "axios";
import config from "../config";
import { isApiRequest, toApiUrl } from "../util/apiUtils";

const envConfig = () => {
    if(window.location.host.includes(".test.")){
        return config.auth.test;
    }
    if(window.location.host.includes("referansenummer.vy.no")){
        return config.auth.prod;
    }
}

axios.interceptors.request.use(request => {
    request.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
    request.headers["Content-Type"] = "application/json";
    request.headers["X-Requested-With"] = "XMLHttpRequest";

    if(isApiRequest(request.url)){
        request.url = toApiUrl(request.url);
    }

    return request;
},(error) => {
    return error;
})

axios.interceptors.response.use(response => response,
    function (error) {
        if (error.response?.status === 500) {
            return error.response.status;
        }

        if (
            error.response?.status === 401 &&
            error.config &&
            !error.config.__isRetryRequest
        ) {
            return new Promise((resolve, reject) => {
                fetchAndUpdateTokens()
                    .then(() => {
                        console.log("Preparing retry after 401");

                        error.config.__isRetryRequest = true;
                        error.config.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;

                        resolve(axios(error.config));
                    })
                    .catch((error) => {
                        console.log("Error retrying after 401", error);
                        reject(error);
                    });
            });
        }

        return Promise.reject(error);
    }
);

const fetchAndUpdateTokens = async () => {
    console.log("refreshTokens")

    const {
        baseUrl,
        clientId
    } = envConfig();

    const tokenUrl = `${baseUrl}/oauth2/token`;
    const body = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${localStorage.getItem("refreshToken")}`;

    const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body,
    });

    if (!response.ok) {
        return Promise.reject();
    }

    const { access_token, id_token } = await response.json();
    window.localStorage.setItem('accessToken', access_token);
    window.localStorage.setItem('idToken', id_token);

    return Promise.resolve();
}

export default axios;