import Immutable from 'immutable'

//This ignores warning "no-extend-native", do not remove:
/*eslint no-extend-native: ["error", { "exceptions": ["String", "Array"] }]*/

export const arrayFromPolyFill = () => {
    
 // Production steps of ECMA-262, Edition 6, 22.1.2.1
    if (!Array.from) {
      console.warn("Array from does not exists. Polyfilling!")
      Array.from = (function () {
        var toStr = Object.prototype.toString;
        var isCallable = function (fn) {
          return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
        };
        var toInteger = function (value) {
          var number = Number(value);
          if (isNaN(number)) { return 0; }
          if (number === 0 || !isFinite(number)) { return number; }
          return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
        };
        var maxSafeInteger = Math.pow(2, 53) - 1;
        var toLength = function (value) {
          var len = toInteger(value);
          return Math.min(Math.max(len, 0), maxSafeInteger);
        };

        // The length property of the from method is 1.
        return function from(arrayLike/*, mapFn, thisArg */) {
            if(Immutable.List.isList(arrayLike)) {
                return arrayLike.toArray()
            }  
          
          // 1. Let C be the this value.
          var C = this;

          // 2. Let items be ToObject(arrayLike).
          var items = Object(arrayLike);

          // 3. ReturnIfAbrupt(items).
          if (arrayLike == null) {
            throw new TypeError('Array.from requires an array-like object - not null or undefined');
          }

          // 4. If mapfn is undefined, then let mapping be false.
          var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
          var T;
          if (typeof mapFn !== 'undefined') {
            // 5. else
            // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
            if (!isCallable(mapFn)) {
              throw new TypeError('Array.from: when provided, the second argument must be a function');
            }

            // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
            if (arguments.length > 2) {
              T = arguments[2];
            }
          }

          // 10. Let lenValue be Get(items, "length").
          // 11. Let len be ToLength(lenValue).
          var len = toLength(items.length);

          // 13. If IsConstructor(C) is true, then
          // 13. a. Let A be the result of calling the [[Construct]] internal method 
          // of C with an argument list containing the single item len.
          // 14. a. Else, Let A be ArrayCreate(len).
          var A = isCallable(C) ? Object(new C(len)) : new Array(len);

          // 16. Let k be 0.
          var k = 0;
          // 17. Repeat, while k < len… (also steps a - h)
          var kValue;
          while (k < len) {
            kValue = items[k];
            if (mapFn) {
              A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
            } else {
              A[k] = kValue;
            }
            k += 1;
          }
          // 18. Let putStatus be Put(A, "length", len, true).
          A.length = len;
          // 20. Return A.
          return A;
        };
      }());
    } else  { //Extremely dirty hack for IE. Should search for better solution. But it works for now:
        const immutableList = Immutable.List.of("A", "B")
        const arrayFrom = Array.from(immutableList)
        const expectedArray = ["A", "B"]
        console.log("arrayFrom:", arrayFrom, " expected:", expectedArray)
        if( arrayFrom.length !== expectedArray.length) {
            const existingArrayFromFunc = Array.from
            Array.from = function(arrayLike) {
                if(Immutable.List.isList(arrayLike)) {
                    return arrayLike.toArray()
                }
                existingArrayFromFunc(arrayLike)
            }
        }
    }
};

export const stringIncludesPolyfill = () => {
    if (!String.prototype.includes) {
      String.prototype.includes = function(search, start) {
        if (typeof start !== 'number') {
          start = 0;
        }

        if (start + search.length > this.length) {
          return false;
        } else {
          return this.indexOf(search, start) !== -1;
        }
    };
  }
};

export const arrayIncludesPolyfill = () => {
    if (!Array.prototype.includes) {
        Object.defineProperty(Array.prototype, "includes", {
          enumerable: false,
          value: function(obj) {
              var newArr = this.filter(function(el) {
                return el === obj;
              });
              return newArr.length > 0;
            }
        });
      }
};

export const stringStartsWithPolyfill = () => {
    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (str){
          return this.lastIndexOf(str, 0) === 0;
        };
      }
};


export const stringEndsWithPolyfill = () => {
    if (!String.prototype.endsWith) {
        String.prototype.endsWith = function(searchString, position) {
            var subjectString = this.toString();
            if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
              position = subjectString.length;
            }
            position -= searchString.length;
            var lastIndex = subjectString.lastIndexOf(searchString, position);
            return lastIndex !== -1 && lastIndex === position;
        };
      }
};

export const padStartPolyfill = () => {
    if (!String.prototype.padStart) {
        String.prototype.padStart = function padStart(targetLength,padString) {
            targetLength = targetLength>>0; //floor if number or convert non-number to 0;
            padString = String(padString || ' ');
            if (this.length > targetLength) {
                return String(this);
            }
            else {
                targetLength = targetLength-this.length;
                if (targetLength > padString.length) {
                    padString += padString.repeat(targetLength/padString.length); //append to original to ensure we are longer than needed
                }
                return padString.slice(0,targetLength) + String(this);
            }
        };
    }
};