import React from "react";
import cryptoBrowserify from "crypto-browserify";
import jwt from "jsonwebtoken";
import jwksClient from "jwks-rsa";

class Auth extends React.Component {

    constructor(props) {
        super(props);

        const {
            baseUrl,
            clientId,
            redirectUri,
            jwksUrl,
            SCOPE
        } = props.config;

        this.state = {
            baseUrl,
            clientId,
            redirectUri,
            jwksUrl,
            SCOPE,

            challenge: "",
            verifier: "",

            accessJwk: {},
            idJwk: {},
            isAuthorized: false,

            accessToken: localStorage.getItem('accessToken'),
            idToken: localStorage.getItem('idToken'),
            refreshToken: localStorage.getItem('refreshToken'),
            storedVerifier: localStorage.getItem('verifier')
        };



        function base64URLEncode(str) {
            return str.toString('base64')
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=/g, '');
        }

        function sha256(buffer) {
            return cryptoBrowserify.createHash('sha256').update(buffer).digest();
        }

        if (this.state.storedVerifier === null) {
            const newVerifier = base64URLEncode(cryptoBrowserify.randomBytes(32));
            window.localStorage.setItem('verifier', newVerifier);
            this.state.storedVerifier = newVerifier;
        }

        this.state.challenge = base64URLEncode(sha256(this.state.storedVerifier));
    }

    /**
     * Refresh tokens.
     * @param refreshToken used to get a new Access Token
     */
    refreshTokens = async (refreshToken) => {
        console.log("refreshTokens")

        const {
            clientId
        } = this.state;

        const body = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`;
        const response = await fetch(this.tokenUrl(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body,
        });

        /**
         * invalid_request
         The request is missing a required parameter, includes an unsupported parameter value (other than unsupported_grant_type), or is otherwise malformed. For example, grant_type is refresh_token but refresh_token is not included.

         invalid_client
         Client authentication failed. For example, when the client includes client_id and client_secret in the authorization header, but there's no such client with that client_id and client_secret.

         invalid_grant
         Refresh token has been revoked.

         Authorization code has been consumed already or does not exist.

         unauthorized_client
         Client is not allowed for code grant flow or for refreshing tokens.

         unsupported_grant_type
         Returned if grant_type is anything other than authorization_code or refresh_token.
         */
        if (!response.ok) {
            const { error } = await response.json();
            console.log(`Handle error for refresh "${error}"`)
            // Best to just logout, clean storage, let the user login again.
            return this.logout();
        }

        const { access_token, id_token } = await response.json();
        window.localStorage.setItem('accessToken', access_token);
        window.localStorage.setItem('idToken', id_token);

        this.setState(state => ({
            ...state,
            accessToken: access_token,
            idToken: id_token,
            isAuthorized: true
        }))
    };

    /**
     * Request tokens from Cognito by authorization_code.
     * Using the PKCE challenge to issue new tokens for the webapp.
     *
     * @param code token exchange code.
     */
    login = async (code) => {
        const {
            clientId,
            verifier,
            redirectUri
        } = this.state;

        const body = `grant_type=authorization_code&client_id=${clientId}&code_verifier=${verifier}&code=${code}&redirect_uri=${redirectUri}`;

        const response = await fetch(this.tokenUrl(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body,
        });

        if (!response.ok) {
            const { error}  = await response.json()
            console.log(`Handle error "${error}"`)
            throw Error(error);
        }else{
            console.log("Received tokens")
            const { access_token, id_token, refresh_token } = await response.json();

            window.localStorage.setItem('accessToken', access_token);
            window.localStorage.setItem('idToken', id_token);
            window.localStorage.setItem('refreshToken', refresh_token);

            this.setState(state => ({
                ...state,
                idToken: id_token,
                acessToken: access_token,
                refreshToken: refresh_token,
                isAuthorized: true
            }))

            window.history.replaceState({}, document.title, "/");
        }
    }

    /**
     * Remove tokens from localstorage.
     */
    logout = async () => {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('idToken');
        window.localStorage.removeItem('refreshToken');
        this.setState(state => ({
            ...state,
            accessToken: null,
            refreshToken: null,
            idToken: null,
            isAuthorized: false
        }));
    };

    /**
     * PublicKey callback
     * @param header
     * @param callback
     */
    getKey = (header, callback) => {
        const client = jwksClient({
            rateLimit: true,
            jwksRequestsPerMinute: 2,
            jwksUri: this.state.jwksUrl
        });

        client.getSigningKey(header.kid, function(err, key) {
            const signingKey = key.publicKey || key.rsaPublicKey;
            callback(null, signingKey);
        });
    }

    /**
     * Verifies an accessToken. Uses the publicKey-callback
     * @returns {Promise<*>}
     */
    verifyAccessToken = (callback) => {
        jwt.verify(this.state.accessToken, this.getKey, {}, callback);
    }

    redirectToAzureCognitoLogin = () => {
        const {
            clientId,
            baseUrl,
            SCOPE,
            redirectUri
        } = this.state;

        window.location.assign(`${baseUrl}/login?client_id=${clientId}&response_type=code&scope=${SCOPE}&redirect_uri=${redirectUri}`);
    }

    tokenUrl = () => {
        return `${this.state.baseUrl}/oauth2/token`;
    }

    loginUrl = () => {
        const {
            baseUrl,
            SCOPE,
            clientId,
            challenge,
            redirectUri
        } = this.state;

        return `${baseUrl}/oauth2/authorize?scope=${SCOPE}&response_type=code&client_id=${clientId}&code_challenge=${challenge}&code_challenge_method=S256&redirect_uri=${redirectUri}`;
    }

    logoutUrl = () => {
        const {
            baseUrl,
            clientId,
            redirectUri
        } = this.state;

        return `${baseUrl}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;
    }

    tokens = () => {
        return {
            accessToken: this.state.accessToken,
            idToken: this.state.idToken,
            refreshToken: this.state.refreshToken
        };
    }

    /**
     * 1. Verifies tokens. Given exceptions (tokenExpiration, malformed) resort to step 2.
     * 2. Check if existing tokens are valid. Either refresh tokens, login with code_flow or redirect to login.
     */
    componentDidMount() {
        const code = new URLSearchParams(window.location.search).get("code");

        this.verifyAccessToken((err, _) => {
            if(err) {
                if(this.state.refreshToken){
                    this.refreshTokens(this.state.refreshToken);
                    return;
                }

                if(code){
                    this.login(code);
                    return;
                }

                this.redirectToAzureCognitoLogin();
                return;
            }
            this.setState(state => ({...state, isAuthorized: true}))
        });

    }

    renderNotLoggedIn = () => {
        return (
            <div>
                <h1>You're not logged in</h1>
                <p>Refresh page to log in</p>
            </div>
        )
    }

    render() {
        const { isAuthorized } = this.state;
        const { children } = this.props;

        return isAuthorized ? children : this.renderNotLoggedIn;
    }
}

export default Auth;