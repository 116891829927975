import {applyMiddleware, compose, createStore} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const configureStore = () => {
    var middleware = compose (
        applyMiddleware(
            thunk
        ),
        window.devToolsExtension ? window.devToolsExtension() : f => f // if redux developer tools browser extension is installed
    );

    return createStore(
        rootReducer,
        middleware
    )
};

export default configureStore
