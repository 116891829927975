import Immutable from 'immutable';
import * as types from '../../actions'
import moment from 'moment'


const time = (state = Immutable.fromJS({
    todayMidnight:  moment().startOf('day').unix(),
}), action = {}) => {
  switch (action.type) {
    case types.RECALCULATE_TIME:
        const newTodayMidnight = moment().startOf('day').unix()
        if(newTodayMidnight !== state.get('todayMidnight')) {
            return Immutable.fromJS({ todayMidnight: newTodayMidnight })
        } else {
            return state
        }
    default:
      return state
  }
}


export default time