import Immutable from 'immutable';
import * as types from '../../../actions'

const value = (state = Immutable.fromJS({
}), action = {}) => {
  switch (action.type) {
    case types.CHANGE_QUICK_SEARCH_VALUE:
      const quickSearchName = action.quickSearchName
      const value = action.value || ""
      if(quickSearchName ) {
          return state.setIn( [quickSearchName], value )
      }
      return state

    default:
      return state
  }
}

export default value