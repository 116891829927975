import React from 'react'
import {t} from 'react-i18nify'

const LoadingPage = ({loading, applicationName}) =>
<div className="apploading">
    <div className="container">
        <div className="row">
            <div className="col-xs-12 col-sm-6 col-sm-offset-3">
                {applicationName != null && t('loadingPage.label.loading', {applicationName: applicationName})}
                {applicationName == null && <span>&nbsp;</span>}
            </div>
        </div>
        <div className="row">
            <div className="col-xs-12 col-sm-6 col-sm-offset-3">
                <div className="progress">
                    <div className="progress-bar progress-bar-striped active progress-bar-animated" role="progressbar" aria-valuenow={loading.get('percent')} aria-valuemin="0" aria-valuemax="100" style={{width: loading.get('percent') + "%"}}>
                        <span className="sr-only">{loading.get('percent')}% Complete</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

export default LoadingPage