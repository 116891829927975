export const translations = {
  nb: {
    general: {
      form: {
        validation: {
          required: "Påkrevd felt",
          unique: "%{fieldName} finnes allerede i databasen"
        },
        button: {
          showMoreFields: "Vis flere felter",
          hideMoreFields: "Vis færre felter",
          save: "Lagre",
          cancel: "Avbryt",
          back: "Tilbake"
        }
      }
    },


    loadingPage: {
      label: {
        loading: "Laster %{applicationName}..."
      }
    },
    type: {
      personnel: "Personelltiltak",
      customer: "Kunde",
      empty: "Alle"
    },
    subType: {
      wheelchair: "Rullestol",
      child_seat: "Barnesete",
      other: "Annet",
      driver: "Lokfører",
      conductor: "Konduktør",
      customer_consultant: "Kundeveileder"
    },
    refs: {
      detail: {
        form: {
          headingUpdate: "Referanse #%{refId}",
          headingNew: "Ny referanse",
          label : {
            date: "Dato",
            type: "Type",
            subType: "Undertype",
            comment: "Kommentar",
            trainNumbers: "Tognr.",
            station: "Stasjon",
            originSystem: "Opprettet fra system",
            createdBy: "Opprettet av",
            groupId: "Objektnummer",
            isirkId: "I-sirk id",
            incidentId: "Status incidentid",
            infrastrukturPlanId: "Tiltak planid"
          },
          tooltip: {
            groupId: "Kun ment for regnskapsavdelingen for å gruppere sammen ulike referansenummer for samlet rapportering.",
            bundle: "Kun ment for regnskapsavdelingen for å gruppere sammen ulike referansenummer for samlet rapportering.",
            incidentId: "Status Tiltak sin fane Infrastrukturtiltak øverst til venstre har et datafelt som heter: Incident ID.\nMan kan altså knytte aktuelt referansenummer opp mot dette. Nyttig for regnskapsavdelingen.",
            isirkId: "Aktuelt I-sirk som er knyttet til et brudd. Finnes i Bari etter at det er opprettet i Hastus.\nMan kan altså knytte aktuelt referansenummer opp mot dette. Nyttig for regnskapsavdelingen.",
            infrastrukturPlanId: "Status Tiltak sin fane Infrastrukturtiltak Har feltet «Plan» for hvert tiltak som er planlagt for dette Infrastrukturbruddet.\nMan kan altså knytte aktuelt referansenummer opp mot et slikt nummer."
          },
          bottomText: "Lagre-knappen blir aktivert når utfylt skjermbilde gjør det aktuelt.",
          submitMessage: {
            noIds: "Referanse opprettet. Tognr/Stasjon samt I-sirk/Tiltak-planid er ikke angitt på referansen."
          },
          changesSaved: "Endringer lagret.",
          isirkUsedError: "I-sirk id er allerede benyttet for referansenummer denne dato.",
          planIdUsedError: "Tiltak planid er allerede benyttet for referansenummer denne dato."
        },
        accessLogTable: {
          heading: "Aktivitetslogg",
          label: {
            createdBy: "Oppretter",
            type: "Type",
            created: "Dato",
            subType: "Undertype",
            comment: "Kommentar"
          }
        }
      },
      table: {
        heading: "Oversikt/ajourhold av referansenummer i Vy",
        label: {
          refId: "Refnr",
          date: "Dato (maks %{maxDays} dager)",
          type: "Type",
          groupId: "Obj. nr",
          isirkId: "I-sirk id",
          incidentId: "Incidentid",
          infrastrukturPlanId: "Planid",
          trainNumbers: "Tognr.",
          station: "Stasjon",
          details: "Detaljer",
          comment: "Kommentar"
        },
        filter: {
          label: {
            refId: "",
            date: "",
            type: "",
            groupId: "",
            isirkId: "",
            incidentId: "",
            infrastrukturPlanId: "",
            trainNumbers: "",
            accessLog: ""
          }
        },
        actions: {
          bundle: {
            groupId: "Obj.nr",
            button: "Bundle"
          },
          open: {
            refnr: "Refnr",
            button: "Åpne"
          },
          new : "Ny referanse",
          newShort : "Ny ref.",
          excel: {
            simple: "Excel-export av tabell",
            details: "Excel-export med detaljer"
          }
        },
        alert: {
          refNotFound: "Referansenummer '%{refId}' eksisterer ikke."
        },
        content: {
          empty: "Ingen resultater"
        }
      }
    }

  }
};