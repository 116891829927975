import React from 'react'
import { connect } from 'react-redux'

const MODAL_COMPONENTS = {
}

let ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null
  }

const SpecificModal = MODAL_COMPONENTS[modalType]
  return <SpecificModal {...modalProps} />
}

ModalRoot = connect(
  state => {
      const m = state.get('gui').get('modal')
      return {modalType: m.modalType, modalProps: m.modalProps}
  }
)(ModalRoot)

export default ModalRoot