import React, {Component} from 'react';
import {connect} from 'react-redux'
import {t} from 'react-i18nify'
import {Field, formValueSelector, reduxForm} from 'redux-form/immutable'
import {Link} from 'react-router-dom';
import FormatDate, {FormatDateToString, Parse} from '../util/FormatDate'
import DropdownList from "react-widgets/lib/DropdownList";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {Calendar} from 'react-date-range';
import {nb} from 'react-date-range/dist/locale';
import Popup from "reactjs-popup";
import {addDays, differenceInCalendarDays} from 'date-fns';
import {
  bundleRefs,
  fetchRef,
  fetchRefs,
  MAX_DAYS_RANGE
} from "../../actions/creators/refs";
import {getAllRefTypesWithLabel} from "../../selectors/refs/refTypes";
import Immutable from "immutable";
import { fetchDataAxios } from "../../util/fetchUtils";
import {NO_FORMAT, ParseDateTime} from "../util/FormatDateTime";
import moment from "moment";
import "../refs/Refs.css"

class RefsTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filterFields: [{name: 'refId'}, {name: 'start'}, {name: 'type', exactMatch: true}, {name: 'groupId'}, {name: 'isirkId'}, {name: 'incidentId'}, {name: 'infrastrukturPlanId'}, {name: 'trainNumbers'}, {name: 'accessLog', comment: true}],
      startDate: addDays(new Date(), -(MAX_DAYS_RANGE - 1)),
      endDate: new Date(),
      checkedRefIds : [],
      excelSpinner: false,
      excelDetailsSpinner: false
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
  }

  onFilterChange(fieldName, newValue) {
    this.setState({
      [fieldName]: newValue
    });
  }

  getFilteredRefs() {
    return this.props.refs.filter(r =>
        !r.get('hidden') &&
        this.state.filterFields.every(field => {
          let name = field.name;
          let val = this.state[name];
          let exactMatch = field.exactMatch;
          let comment = field.comment && r.get(name)?.flatMap(x => x.get('comment')).join("").toLowerCase();
          return val == null
              || val === ''
              || (r.get(name) && ((exactMatch && r.get(name).toString() === val) || (!exactMatch && r.get(name).indexOf(val) >= 0)))
              || (field.comment && comment.search(val.toLowerCase()) >= 0)
        })
    );
  }

  sortRefs(refs) {

    return refs.sort((r1, r2) => {
      var dateSort = Parse(r2.get("date")) - Parse(r1.get("date"));
      if(dateSort !== 0) {
        return dateSort;
      }

      var timeSort = ParseDateTime(r2.get("created")) - ParseDateTime(r1.get("created"));
      if(timeSort !== 0) {
        return timeSort;
      }

      return r2.get("id") - r1.get("id");
    });
  }

  setStartDate (date, onDateRangeSet) {
    var days = differenceInCalendarDays(this.state.endDate, date);

    if(days >= MAX_DAYS_RANGE) {
      this.setState({
        endDate: addDays(date, MAX_DAYS_RANGE - 1)
      });
    }
    else if(days < 0) {
      this.setState({
        endDate: date
      });
    }

    this.setState({
      startDate: date
    }, function() {
      onDateRangeSet(this.state.startDate, this.state.endDate)
    });
  };

  setEndDate (date, onDateRangeSet) {
    var days = differenceInCalendarDays(date, this.state.startDate);

    if(days >= MAX_DAYS_RANGE) {
      this.setState({
        startDate: addDays(date,  -(MAX_DAYS_RANGE - 1))
      });
    }
    else if(days < 0) {
      this.setState({
        startDate: date
      });
    }

    this.setState({
      endDate: date
    }, function() {
      onDateRangeSet(this.state.startDate, this.state.endDate)
    });
  };

  renderFilterField ({input, type, meta: { touched, error }, ...rest}) {
    return <input {...input} {...rest}/>
  }

  renderSimpleField ({input, type, meta: { touched, error }, ...rest}) {
    return <input {...input} id={input.name} {...rest}/>
  }

  renderFilterDropdown ({input, filter, meta: { touched, error }, ...rest}) {
    return <DropdownList {...input}  {...rest}/>
  }

  downloadExcelExport (refs, details) {
    this.setState({
      excelSpinner: !details,
      excelDetailsSpinner: details
    });

    const ids = refs.map(r => r.get('id')).join(",");

    fetchDataAxios('/tiltaksreferanse/refs/excel?ids=' + ids + "&details=" + details, null, { responseType: "blob" })
    .then(response => {
      this.setState({
        excelDetailsSpinner: false,
        excelSpinner: false,
      });

      var filename = "Referanser-" + (details ? "detaljer-" : "") +  moment(Date.now()).format(NO_FORMAT) + ".xlsx";
      filename = filename.replace(/:/g, ".");

      let url = window.URL.createObjectURL(new Blob([response.data]));
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    });
  }

  onRefCheck = (refId) => (event) => {
    if(event.target.checked) {
      this.setState(prevState => ({
        checkedRefIds: [...prevState.checkedRefIds, refId]
      }));
    }
    else {
      const index = this.state.checkedRefIds.indexOf(refId);

      this.setState(prevState => ({
        checkedRefIds: prevState.checkedRefIds.filter((id, i) => i !== index)
      }));
    }
  };

  renderButtonRow(onOpenInputRef, onBundleRefs, refs) {

    //Only bundle refs which are checked and currently visible
    const bundleRefIds = this.state.checkedRefIds.filter(checkedId => refs.find(r => r.get('id') === checkedId));

    return <div className="row" style={{maxWidth: 1100}}>
      <div className="col-sm" style={{marginBottom: 15, marginRight: 5}}>
        <Link className="btn btn-secondary btn-sm" to={"/refs/new/"} role="button">{t('refs.table.actions.new')}</Link>
      </div>
      <div className="col-sm" style={{marginBottom: 5, marginRight: 5}}>
          <Field name="openRefId" component={this.renderSimpleField} type="text" placeholder={t('refs.table.actions.open.refnr')} className="max-w-90" style={{marginRight: 5}}/>
          <button className="btn btn-secondary btn-sm" onClick={() => onOpenInputRef()}>{t('refs.table.actions.open.button')}</button>
      </div>
      <div className="col-sm" style={{marginBottom: 5, marginRight: 5}}>
          <Field name="bundleGroupId" component={this.renderSimpleField} type="text" placeholder={t('refs.table.actions.bundle.groupId')} title={t('refs.detail.form.tooltip.groupId')} className="max-w-90" style={{marginRight: 5}}/>
          <button className="btn btn-secondary btn-sm" onClick={() => onBundleRefs(bundleRefIds)} title={t('refs.detail.form.tooltip.groupId')}>{t('refs.table.actions.bundle.button')}</button>
      </div>
      <div className="col-sm" style={{marginBottom: 5, marginRight: 5}}>
        <button className="btn btn-secondary btn-sm" onClick={() => this.downloadExcelExport(refs, false)} disabled={this.state.excelSpinner}>
          {this.state.excelSpinner && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>}
          {t('refs.table.actions.excel.simple')}
        </button>
      </div>
      <div className="col-sm" style={{marginBottom: 5, marginRight: 5}}>
        <button className="btn btn-secondary btn-sm" onClick={() => this.downloadExcelExport(refs, true)} disabled={this.state.excelDetailsSpinner}>
          {this.state.excelDetailsSpinner && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>}
          {t('refs.table.actions.excel.details')}
        </button>
      </div>
    </div>
  }

  render() {
    const {refTypes, onDateRangeSet, onOpenInputRef, refIdToOpen, onBundleRefs, bundleGroupId,  history} = this.props;

    var refs = this.getFilteredRefs();
    refs = this.sortRefs(refs);

    return <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <h3>{t('refs.table.heading')}</h3>
            <div className="h-15p"/>

            {this.renderButtonRow(onOpenInputRef(refIdToOpen, history), onBundleRefs(bundleGroupId), refs)}

            <table className="table table-striped table-hover table-condensed table-outside-border table-space table-fixed-header">
              <thead className="border-bottom">
              <tr className="row" style={{paddingBottom:'0px'}}>
                <th className="col-1 border-0" style={{paddingLeft:'20px', marginBottom:'0px'}}>
                  <div>{t('refs.table.label.refId')}</div>
                  <div><Field name="refId"
                              component={this.renderFilterField}
                              type="text"
                              placeholder={t('refs.table.filter.label.refId')}
                              onChange={e => this.onFilterChange(e.target.name, e.target.value)}
                              className="max-w-75"
                  />
                  </div>
                </th>
                <th className="col-2 border-0">
                  <div>
                    {t('refs.table.label.date', {maxDays: MAX_DAYS_RANGE})}
                  </div>
                  <div>
                    <Popup trigger={
                      <input readOnly value={FormatDateToString(this.state.startDate)} className="min-w-85" />
                    } on="click" position="right top" contentStyle={{width: '345px'}}>
                      { close => (<Calendar
                              onChange={date => {
                                  this.setStartDate(date, onDateRangeSet)
                                  close()
                                }}
                              locale={nb}
                              date={this.state.startDate}/>
                      )}
                    </Popup>
                    -
                    <Popup trigger={
                      <input readOnly value={FormatDateToString(this.state.endDate)} className="min-w-85" />
                    } on="click" position="right top" contentStyle={{width: '345px'}}>
                      { close => (<Calendar
                              onChange={date => {
                                  this.setEndDate(date, onDateRangeSet)
                                  close()
                                }}
                              locale={nb}
                              date={this.state.endDate}/>
                      )}
                    </Popup>
                  </div>
                </th>
                <th className="col-1 border-0 min-w-140">
                  <div>
                    {t('refs.table.label.type')}
                  </div>
                  <div>
                    <Field
                        name="type"
                        component={this.renderFilterDropdown}
                        data={refTypes.toJS()}
                        valueField={"code"}
                        textField="label"
                        normalize={(value) => value && value.code}
                        placeholder={t('refs.table.filter.label.type')}
                        onSelect={v => this.onFilterChange("type", v.code, true)}
                        className="min-w-140 max-h-30"
                    />
                  </div>
                </th>
                <th className="col-3 border-0">
                  <div className="row">
                    <div className="col-3">
                      <div>{t('refs.table.label.groupId')}</div>
                      <div>
                        <Field name="groupId" component={this.renderFilterField} type="text" placeholder={t('refs.table.filter.label.groupId')} onChange={e => this.onFilterChange(e.target.name, e.target.value)} className="max-w-75" title={t('refs.detail.form.tooltip.groupId')}/>
                      </div>
                    </div>
                    <div className="col-3">
                      <div>{t('refs.table.label.isirkId')}</div>
                      <div>
                        <Field name="isirkId" component={this.renderFilterField} type="text" placeholder={t('refs.table.filter.label.isirkId')} onChange={e => this.onFilterChange(e.target.name, e.target.value)} className="max-w-75" title={t('refs.detail.form.tooltip.isirkId')}/>
                      </div>
                    </div>
                    <div className="col-3">
                      <div>{t('refs.table.label.incidentId')}</div>
                      <div>
                        <Field name="incidentId" component={this.renderFilterField} type="text" placeholder={t('refs.table.filter.label.incidentId')} onChange={e => this.onFilterChange(e.target.name, e.target.value)} className="max-w-100" title={t('refs.detail.form.tooltip.incidentId')}/>
                      </div>
                    </div>
                    <div className="col-3">
                      <div>
                        {t('refs.table.label.infrastrukturPlanId')}
                      </div>
                      <div>
                        <Field name="infrastrukturPlanId" component={this.renderFilterField} type="text" placeholder={t('refs.table.filter.label.infrastrukturPlanId')} onChange={e => this.onFilterChange(e.target.name, e.target.value)} className="max-w-75" title={t('refs.detail.form.tooltip.infrastrukturPlanId')}/>
                      </div>
                    </div>
                  </div>
                </th>
                <th className="col-1 border-0">
                  <div>
                    {t('refs.table.label.trainNumbers')}
                  </div>
                  <div>
                    <Field name="trainNumbers" component={this.renderFilterField} type="text" placeholder={t('refs.table.filter.label.trainNumbers')} onChange={e => this.onFilterChange(e.target.name, e.target.value)} className="max-w-75"/>
                  </div>
                </th>
                <th className="col-3 border-0">
                  <div>
                    {t('refs.table.label.comment')}
                  </div>
                  <div>
                    <Field name="accessLog" style={{width: '100%'}} component={this.renderFilterField} type="text" placeholder={t('refs.table.filter.label.accessLog')} onChange={e => this.onFilterChange(e.target.name, e.target.value)}/>
                  </div>
                </th>
                <th className="col-1 border-0 max-w-75"/>
              </tr>
              </thead>
              <tbody>
              {refs.map(ref => {
                const id = ref.get('id');
                const comment = ref.get('accessLog')?.flatMap(x => x.get('comment')).join("");

                return <tr key={id} className="row">
                  <td className="col-1">
                    <p style={{marginLeft:'10px'}}>
                      <input type="checkbox" style={{marginRight:'5px'}} onChange={(e) => this.onRefCheck(id)(e)} defaultChecked={this.state.checkedRefIds.indexOf(id) !== -1}/>
                      <label>{ref.get('refId')}</label>
                    </p>
                  </td>
                  <td className="col-2"><FormatDate date={ref.get('date')}/></td>
                  <td className="col-1">{t('type.' + ref.get('type').toLowerCase())}</td>
                  <td className="col-3" style={{paddingLeft: '0px', paddingRight: '0px', marginLeft: '12px'}}>
                    <div className="row" style={{width:'100%'}}>
                      <div style={{width:'25%', paddingLeft: '12px'}}>{ref.get('groupId')}</div>
                      <div style={{width:'25%', paddingLeft: '12px'}}>{ref.get('isirkId')}</div>
                      <div style={{width:'25%', paddingLeft: '12px'}}>{ref.get('incidentId')}</div>
                      <div style={{width:'25%', paddingLeft: '12px'}}>{ref.get('infrastrukturPlanId')}</div>
                    </div>
                  </td>
                  <td className="col-1">{ref.get('trainNumbers')?.join(", ")}</td>
                  <td className="col-3">{comment}</td>
                  <td className="col-1 max-w-75" style={{display:'inline-block'}}>
                    <Link className="btn btn-primary btn-sm" to={"/refs/edit/" + ref.get('id')} role="button">{t('refs.table.label.details')}</Link>
                  </td>
                </tr>
              })}
              {refs.size === 0 && <tr><td colSpan="10"><i>{t('refs.table.content.empty')}</i></td></tr>}
              </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
  }
}

RefsTable = reduxForm({
  form: 'refsTable'
})(RefsTable);

const formSelector = formValueSelector('refsTable');
RefsTable = connect(
    (state, ownProps) => {
      const selector = ownProps.selector;
      const refs = selector(state, ownProps);

      var refTypes = getAllRefTypesWithLabel(state);
      refTypes = refTypes.unshift(
          Immutable.fromJS({code: "", label: t('type.empty')})
      );

      const refIdToOpen = formSelector(state, 'openRefId');
      const bundleGroupId = formSelector(state, 'bundleGroupId');

      return {
        refs,
        refTypes,
        refIdToOpen,
        bundleGroupId,
        ...ownProps
      }},
    (dispatch) => {

      dispatch(fetchRefs())

      return {
        onDateRangeSet: (startDate, endDate) => {
          dispatch(fetchRefs(startDate, endDate))
        },
        onOpenInputRef: (refId, history) => () => {
          refId = refId && refId.trim();

          if(refId) {
            dispatch(fetchRef(refId)).then(result => {
              if(result) {
                history.push("/refs/edit/" + result.ref.id);
              }
            });

          }
        },
        onBundleRefs: (group) => (ids) => {
          if(ids.length > 0) {
            dispatch(bundleRefs(ids, group))
          }
        }
      }
    }
)(RefsTable);
export default RefsTable;