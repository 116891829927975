import Immutable from 'immutable';
import * as types from '../../actions'

const refTypes = (state = Immutable.fromJS([]), action = {}) => {
  switch (action.type) {
    case types.FETCH_REF_TYPES.SUCCESS:
      return Immutable.fromJS(action.refTypes);
    default:
      return state
  }
};

export default refTypes