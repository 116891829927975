import { combineReducers } from 'redux-immutable'

import fetchStatus from './fetchStatus.js'
import modal from './modal.js'
import quickSearch from './quickSearch/index'
import time from './time'

export default combineReducers({
    fetchStatus,
    quickSearch,
    modal,
    time
  })