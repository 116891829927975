import {createSelector} from "reselect";
import {t} from 'react-i18nify'

const getAllRefSubtypes = (state) => state.get('data').get('refSubtypes');

export const getRefSubtypesWithLabel = (type) => createSelector(
    [getAllRefSubtypes],
    (all) => {
      return all.get(type)?.map(subType => {
        return {code: subType, label: t('subType.' + subType.toLowerCase())}
      })
    }
);