import config from '../config'
import Url from "url";

const devEnv = process.env.NODE_ENV === "development";
const prodEnvName = "prod";

export const isApiRequest = (url) => {
  return url.indexOf(config.api.path) !== -1
};

export const toApiUrl = (url) => {
  const parsedUrl = Url.parse(url);
  const env = getEnv();

  url = addApiVersion(parsedUrl, url);

  if (!devEnv && !parsedUrl.protocol && !parsedUrl.hostname) {
    if(env === prodEnvName) {
      url = config.api.prodHost + url
    }
    else {
      url = config.api.urlPrefix + getHostedZone() + url
    }
  }

  return url
};

const addApiVersion = (parsedUrl, url) => {
  var versionParam = config.api.versionParam;

  if(parsedUrl.query == null) {
    return url + "?" + config.api.versionParam + "=" + config.api.version
  }
  else if(parsedUrl.query.indexOf(versionParam) === -1) {
    return url + "&" + versionParam + "=" + config.api.version
  }

  return url;
};

const host = () => {
  return window.location.host;
};

const getEnv = () => {
  if(devEnv) {
    return "local";
  }

  const hostParts = host().split('.');
  if(hostParts.length < 5) {
    return prodEnvName
  }

  return hostParts[1];
};

const getHostedZone = () => {
  return host().split('.').splice(1).join('.')
};
