import Immutable from 'immutable';
import * as types from '../../actions'

const initialState = {
  modalType: null,
  modalProps: {}
}

const modal = (state = Immutable.fromJS(initialState), action = {}) => {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps
      }
    case types.HIDE_MODAL:
      return initialState
    default:
      return state
  }
}

export default modal
