import {createFetchActions} from '../util/fetchStatusUtils'

export const FETCH_REFS = createFetchActions('FETCH_REFS');
export const FETCH_REF = createFetchActions('FETCH_REF');
export const FETCH_REF_TYPES = createFetchActions('FETCH_REF_TYPES');
export const FETCH_REF_SUBTYPES = createFetchActions('FETCH_REF_SUBTYPES');
export const UPDATED_REF = 'UPDATED_REF';
export const UPDATED_REFS = 'UPDATED_REFS';

export const CHANGE_QUICK_SEARCH_VALUE = 'CHANGE_QUICK_SEARCH_VALUE';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const RECALCULATE_TIME = 'RECALCULATE_TIME'
