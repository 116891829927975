import Immutable from 'immutable';
import * as types from '../../actions'

const refSubtypes = (state = Immutable.fromJS([]), action = {}) => {
  switch (action.type) {
    case types.FETCH_REF_SUBTYPES.SUCCESS:
      return Immutable.fromJS(action.refSubtypes);
    default:
      return state
  }
};

export default refSubtypes