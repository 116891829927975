import React, {Component} from 'react'
import {Link} from 'react-router-dom';
import {t} from 'react-i18nify'
import {Field, formValueSelector, reduxForm} from 'redux-form/immutable'
import {required} from '../../util/formValidationUtils'
import {getRefSubtypesWithLabel} from "../../selectors/refs/refSubtypes";
import {connect} from 'react-redux'
import Popup from "reactjs-popup";
import {FormatDateToString, Parse} from "../util/FormatDate";
import {Calendar} from "react-date-range";
import {nb} from "react-date-range/dist/locale";

let labelClass = "col-md-1";
let inputClass = "col";

class RefForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showMoreFields: localStorage.getItem('reform.showMoreFields') === "true"
    };
  }

  toggleMoreFields = () => {
      this.setState(state => {
          localStorage.setItem('reform.showMoreFields', !state.showMoreFields)
          return { showMoreFields: !state.showMoreFields}
      })
  }

  typeChanged(type) {
    if(type.target.value === 'CUSTOMER') {
      this.props.change('subType', 'WHEELCHAIR')
    }
    else if(type.target.value === 'PERSONNEL') {
      this.props.change('subType', 'DRIVER')
    }
    else {
      this.props.change('subType', null)
    }
  }

  renderSubtypes(subtypes, update) {
    return <div className="form-group row">
      <label htmlFor="subType" className={labelClass + ' col-form-label'}>{t('refs.detail.form.label.subType')}</label>
      <div className={inputClass}>
        {
          subtypes.map(subtype =>
              <div>
                <label><Field name="subType" component="input" type="radio" value={subtype.code} disabled={update}/> {t('subType.' + subtype.label)}</label>
              </div>
          )
        }
      </div>
    </div>
  }

  renderField = ({input, label, placeholder, markRequired, validate, meta: {touched, error}, ...rest}) => (
      <div className="form-group row">
        <label htmlFor={input.name}
               className={labelClass + ' col-form-label'}>{label}</label>
        <div className={inputClass}>
          <input {...input} id={input.name}
                 className={"form-control" + (touched && error ? ' is-invalid'
                     : '')} {...rest}/>
        </div>
        {touched && error && <span className="text-danger">{error}</span>}
        {(!touched || !error) && markRequired && <span>*</span>}
      </div>
  );

  renderReadOnlyField = (label, value) => (
      <div className="form-group row">
        <label className={labelClass + ' col-form-label'}>{label}</label>
        <div className={inputClass + ' col-form-plaintext text-break'}>
          {value}
        </div>
      </div>
  );

  renderDatePopupField = ({input, change, label, placeholder, markRequired, validate, meta: {touched, error}, ...rest}) => (
      <div className="form-group row">
        <label htmlFor={input.name}
               className={labelClass + ' col-form-label'}>{label}</label>
        <div className={inputClass}>

          <Popup trigger={
            <input {...input} value={FormatDateToString(input.value)}
                   id={input.name}
                   className={"form-control" + (touched && error ? ' is-invalid'
                       : '')} {...rest}/>
          } on="click" position="bottom left" contentStyle={{width: '345px'}}>
              { close => (<Calendar
                  onChange={date => {
                      change(input.name, FormatDateToString(date))
                      close()
                  }}
                  locale={nb}
                  date={Parse(input.value) || new Date()}/>
              )}
          </Popup>

        </div>
        {touched && error && <span className="text-danger">{error}</span>}
        {(!touched || !error) && markRequired && <span>*</span>}
      </div>
  );

  renderTextArea = ({input, label, placeholder, markRequired, validate, meta: {touched, error}, ...rest}) => (
      <div className="form-group row">
        <label htmlFor={input.name}
               className={labelClass + ' col-form-label'}>{label}</label>
        <div className={inputClass}>
        <textarea {...input} id={input.name}
                  className={"form-control" + (touched && error ? ' is-invalid'
                      : '')} {...rest}>
          {input.value}
        </textarea>
        </div>
        {touched && error && <span className="text-danger">{error}</span>}
        {(!touched || !error) && markRequired && <span>*</span>}
      </div>
  );

  render() {

    const {subtypes, change, handleSubmit, pristine, submitting, invalid, update, initialValues, submitMessage} = this.props;

    return <form onSubmit={handleSubmit}>

      <Field name="date" label={t('refs.detail.form.label.date')}
             component={this.renderDatePopupField} type="text" disabled={update}
             validate={[required]} markRequired={!update} change={change}/>

      {update && (
          this.renderReadOnlyField(t('refs.detail.form.label.originSystem'),
              initialValues.get("originSystem").split('@')[0])
      )}

      {update && (
          this.renderReadOnlyField(t('refs.detail.form.label.createdBy'),
              initialValues.get("createdBy").split('@')[0])
      )}

      <div className="form-group row">
        <label htmlFor="type" className={labelClass + ' col-form-label'}>{t('refs.detail.form.label.type')}</label>
        <div className={inputClass}>
          <div>
            <label><Field name="type" component="input" type="radio" value="PERSONNEL" onChange={(type) => this.typeChanged(type)} disabled={update} validate={[required]}/> Personell</label>
          </div>
          <div>
            <label><Field name="type" component="input" type="radio" value="CUSTOMER" onChange={(type) => this.typeChanged(type)} disabled={update} validate={[required]}/> Kunde</label>
          </div>
        </div>
      </div>

      {subtypes && !update && this.renderSubtypes(subtypes.toJS(), update)}

      {!update && (
          <Field name="comment" component={this.renderTextArea} rows={8}
                 label={t('refs.detail.form.label.comment')}
                 markRequired={!update}
                 validate={(!update && ([required])) || []}/>
      )}

      {update && (
          <Field
              name={"accessLog[" + (initialValues.get('accessLog').size - 1)
              + "].comment"} component={this.renderTextArea} rows={8}
              label={t('refs.detail.form.label.comment')}/>
      )}

      <Field
          name="trainNumbers"
          label={t('refs.detail.form.label.trainNumbers')}
          component={this.renderField} type="text"/>

      {this.state.showMoreFields && <Field
              name="groupId"
              label={t('refs.detail.form.label.groupId')}
              component={this.renderField} type="text"
              title={t('refs.detail.form.tooltip.groupId')}/>}

      {this.state.showMoreFields && <Field
        name="station"
        label={t('refs.detail.form.label.station')}
        component={this.renderField} type="text"/>}

      {this.state.showMoreFields && <Field
        name="isirkId"
        label={t('refs.detail.form.label.isirkId')}
        component={this.renderField} type="text"
        title={t('refs.detail.form.tooltip.isirkId')}/>}

      {this.state.showMoreFields && <Field
        name="infrastrukturPlanId"
        label={t('refs.detail.form.label.infrastrukturPlanId')}
        component={this.renderField} type="text"
        title={t('refs.detail.form.tooltip.infrastrukturPlanId')}/>}

      {this.state.showMoreFields && <Field
        name="incidentId"
        label={t('refs.detail.form.label.incidentId')}
        component={this.renderField} type="text"
        title={t('refs.detail.form.tooltip.incidentId')}/>}

      <div className="form-group row">
        <div className="col-sm-2"/>
        <div className="col-sm-10 button-row">
          <button className="btn btn-sm btn-secondary" type="button"
                  onClick={() => this.toggleMoreFields()}>
            {t(this.state.showMoreFields ? 'general.form.button.hideMoreFields' : 'general.form.button.showMoreFields')}
          </button>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-2"/>
        <div className="col button-row">
          <button className="btn btn-primary" type="submit"
                  disabled={pristine || submitting || invalid}>{t(
              'general.form.button.save')}</button>
          <Link to="/"
                className={"btn btn-primary" + (submitting ? " disabled"
                    : "")}>{t('general.form.button.back')}</Link>
        </div>
      </div>

      <div className="form-group row">
        <div className="col-sm-2"/>
        <div className="col button-row small">
          {t('refs.detail.form.bottomText')}
        </div>
      </div>

      {submitMessage && (
          <div className="form-group row">
            <div className="col-sm-2"/>
            <div className="col button-row small">
              {t('refs.detail.form.submitMessage.' + submitMessage)}
            </div>
          </div>
      )}
    </form>
  }

}

RefForm = reduxForm({
  form: 'ref'
})(RefForm);

const selector = formValueSelector('ref');
RefForm = connect(
    state => {
      const type = selector(state, 'type');
      const subtypes = type && getRefSubtypesWithLabel(type)(state);

      return {
        subtypes
      }}

)(RefForm);

export default RefForm