import React from 'react'
import {t} from 'react-i18nify'
import FormatDateTime from "../util/FormatDateTime";

let RefAccessLogTable = ({logLines}) =>
    <table className="table table-striped table-hover table-condensed table-space">
      <thead>
        <tr>
          <th>{t('refs.detail.accessLogTable.label.createdBy')}</th>
          <th>{t('refs.detail.accessLogTable.label.type')}</th>
          <th>{t('refs.detail.accessLogTable.label.created')}</th>
          <th>{t('refs.detail.accessLogTable.label.subType')}</th>
          <th>{t('refs.detail.accessLogTable.label.comment')}</th>
        </tr>
      </thead>
      <tbody>
        {logLines.map(log => {
          return <tr key={log.get('id')}>
            <td>{log.get('createdBy').split('@')[0]}</td>
            <td>{log.get('type')}</td>
            <td><FormatDateTime dateTime={log.get('created')}/></td>
            <td>{log.get('subType') && t('subType.' + log.get('subType').toLowerCase())}</td>
            <td>{log.get('comment')}</td>
          </tr>
        })}
      </tbody>
    </table>;

export default RefAccessLogTable;