import Promise from 'es6-promise'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
} from 'react-router-dom'
import moment from 'moment'
import configureStore from './configureStore'
import Application from './components/Application.js'
import {setTranslations, setLocale} from 'react-i18nify'
import {translations} from './i18n/translations'
import {arrayFromPolyFill, stringIncludesPolyfill, stringStartsWithPolyfill, stringEndsWithPolyfill, padStartPolyfill, arrayIncludesPolyfill} from './util/ieUtils'
import * as types from './actions';
import Auth from "./auth/Auth";
import config from "./config";

Promise.polyfill()
arrayFromPolyFill()
arrayIncludesPolyfill()
stringIncludesPolyfill()
stringStartsWithPolyfill()
stringEndsWithPolyfill()
padStartPolyfill()

setTranslations(translations);
setLocale('nb');
moment.locale('nb');

let store = configureStore();

//store.dispatch(fetchRefs());
//store.dispatch(fetchRefTypes());
//store.dispatch(fetchRefSubtypes());

setInterval(() => {
  store.dispatch({type: types.RECALCULATE_TIME})
}, 15 * 1000);

const envConfig = () => {
    if(window.location.host.includes(".test.")){
        return config.auth.test;
    }
    if(window.location.host.includes("referansenummer.vy.no")){
        return config.auth.prod;
    }

    return config.auth.test;
}

ReactDOM.render(
    <Provider store={store}>
      <Router>
          <Auth config={envConfig()}>
              <Application/>
          </Auth>
      </Router>
    </Provider>,
    document.getElementById('app')
);