import React, {useEffect} from 'react';
import 'react-widgets/dist/css/react-widgets.css'
import {connect} from 'react-redux'

import {BrowserRouter as Router, Route} from 'react-router-dom'

import {loadingStatusSelector} from '../selectors/loadingStatus'
import LoadingPage from './LoadingPage'
import RefsPage from './refs/RefsPage'

import ModalRoot from './util/ModalRoot'
import RefEditPage from "./refs/RefEditPage";
import {fetchRefs} from "../actions/creators/refs";
import {fetchRefTypes} from "../actions/creators/refTypes";
import {fetchRefSubtypes} from "../actions/creators/refSubtypes";

let Application = ({loading, applicationName, fetchRefs, fetchRefTypes, fetchRefSubTypes }) => {

    const init = React.useCallback(() => {
        fetchRefs();
        fetchRefTypes();
        fetchRefSubTypes();
    }, [fetchRefs, fetchRefTypes, fetchRefSubTypes])

    useEffect(() => {
        init();
    }, [init])

    return(
         <Router>
          <div>
              {loading.get('loading') && <LoadingPage loading={loading} applicationName={applicationName}/>}
              {!loading.get('loading') && <div>
                  <Route exact path='/' component={RefsPage} />
                  <Route path='/refs/edit/:id' component={RefEditPage} />
                  <Route exact path='/refs/new' component={RefEditPage} />
              </div>
              }
              <ModalRoot/>
          </div>
      </Router>
    );
}

const mapStateToProps = state => ({
    loading: loadingStatusSelector(state),
    applicationName: "Tiltak Referanse"
});

const mapDispatchToProps = dispatch => ({
    fetchRefs: () => dispatch(fetchRefs()),
    fetchRefTypes: () => dispatch(fetchRefTypes()),
    fetchRefSubTypes: () => dispatch(fetchRefSubtypes())
});

export default connect(mapStateToProps,mapDispatchToProps)(Application);