import axios from "../api/axios";

export const postJson = (url, body, config = {}) => fetchJson(url, {...config, method: 'POST', body: body});
export const patchJson = (url, body, config = {}) => fetchJson(url, {...config, method: 'PATCH', body: body});
export const fetchJson = (url, config = {}) => fetchDataAxios(url, "application/json", config).then(res => res.data);

export const fetchDataAxios = (url, contentType = null, config = {}) => {
  const { method, body, ...rest} = config;

  if(method === "POST"){
    return axios.post(url, body, rest);
  }

  if(method === "PATCH") {
    return axios.patch(url, body, rest);
  }
  return axios.get(url, rest);
}
