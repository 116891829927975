import * as types from '../index'
import {makeActionCreator} from '../../util/actionUtils'
import {fetchJson} from '../../util/fetchUtils'

const fetchRefSubtypesRequest = makeActionCreator(types.FETCH_REF_SUBTYPES.REQUEST);
const fetchRefSubtypesSuccess = makeActionCreator(types.FETCH_REF_SUBTYPES.SUCCESS, 'refSubtypes');
const fetchRefSubtypesFailure = makeActionCreator(types.FETCH_REF_SUBTYPES.FAILURE, 'error');

export const fetchRefSubtypes = () => dispatch => {
    dispatch(fetchRefSubtypesRequest());
    return fetchJson(`/tiltaksreferanse/refs/subtypes`)
        .then(
            refSubtypes => dispatch(fetchRefSubtypesSuccess(refSubtypes)),
            error => dispatch(fetchRefSubtypesFailure(error))
        )
};